var context = import.meta.glob("../../components/**/*.{js,jsx,tsx}", { eager: true });

let componentsContext = {};
for (const component in context) {
  componentsContext[component.replace(".jsx", "").replace(".tsx", "").replace(/.+components\//g, "")] = context[component].default;
}

import ReactRailsUJS from "react_ujs";

ReactRailsUJS.getConstructor = (name) => {
  return componentsContext[name];
};
